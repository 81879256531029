@import 'react-big-calendar/lib/sass/styles';

.avatar {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

// rdtPicker styles
.rdtPicker .rdtTimeToggle:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f017';
}
.rdtCounters .rdtCounter:last-child .rdtCount {
  white-space: nowrap;
}

// plans
.plan-cards {
  min-height: 510px;
  position: relative;
  border-width: 3px;

  h1 {
    text-transform: capitalize;
  }

  .btn {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 42.165px);
  }
}

.chat-wrapper {
  .last-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
  }

  .user-message {
    margin-left: 50%;
    margin-bottom: 10px;
    padding-right: 8px;
    .d-flex {
      justify-content: flex-end;
      .card-text {
        padding: 10px;
        background: #e8e8e8;
        border-radius: 20px;
      }
    }
  }
  .partner-message {
    margin-right: 50%;
    margin-bottom: 10px;
    .d-flex {
      .card-text {
        padding: 10px;
        background: #e95095;
        border-radius: 20px;
        color: white;
      }
    }
  }

  .send-msg-wrapper {
    padding-top: 15px;
    border-top: 1px solid #e2e2e2;
    margin-top: 15px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.custom-btn {
  padding: 0.25rem 0.5rem;
  background: #c04ea2;
  color: white;
  border-color: #c04ea2;
  text-transform: capitalize;
  &.disabled,
  &:disabled {
    background: #c04ea2;
    color: white;
  }
}

#sidenav-main .nav-item.active-pro {
  background: white;
  bottom: 0;
  padding-bottom: 10px;
  @media only screen and (max-height: 768px) {
    position: relative;
    padding-bottom: 0;
  }
  @media only screen and (min-width: 768px) {
    max-width: 250px;
  }
  .icon-shape {
    height: 2.5rem;
    width: 2.5rem;
    i {
      font-size: 1rem;
    }
  }
}

.hashtags {
  .avatar {
    border-radius: 0;
    background-color: unset;
    height: 55px;
    width: 55px;
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      border-radius: 0;
    }
  }
}

// .custom-file-input:lang(en) ~ .custom-file-label::after

.custom-file.de {
  .custom-file-label {
    &::after {
      content: 'Durchsuchen';
    }
  }
}

.avatar img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.card-profile-image {
  justify-content: center;
  display: flex;

  img {
    width: 180px;
    height: 180px;
    object-fit: cover;
  }
}

.login-page,
.register-page {
  input,
  textarea {
    color: #000 !important;
  }
}

.custom-file-label {
  z-index: 0;
}
