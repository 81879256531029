.bg-gradient-app {
  background: linear-gradient(87deg, #e95095 0, #7049ba 100%) !important;
}

@media only screen and (max-width: 1366px) {
  .stat-heading {
    max-width: 165px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
